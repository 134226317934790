@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Prompt";
  src: url("./fonts/Prompt-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Prompt";
  src: url("./fonts/Prompt-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Prompt";
  src: url("./fonts/Prompt-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "Prompt", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  color: white;
  background: var(
    --Gradient-Neutral-neu-1,
    linear-gradient(
      0deg,
      var(--Base-Tertiary-tertiary-900, #201957) 0%,
      var(--Base-Tertiary-tertiary-900, #201957) 100%
    ),
    linear-gradient(180deg, #202f67 0%, #201957 100%)
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
